import * as React from 'react'

export type ActionsProps = {
  children: React.ReactNode
}

export const Actions: React.VFC<ActionsProps> = (props) => {
  const { children } = props

  return <div className="ml-auto flex gap-2 flex-shrink-0">{children}</div>
}
